// -----------------------------------------------------------------------------
// This file contains all styles related to the main menu's sidebar of the site/application.
// -----------------------------------------------------------------------------

.sidebar_wrapper {
  display: none;
  text-align: right;
  padding: 5em 2em;
  position: fixed;
  z-index: 9;
  width: 100%;
  float: right;
  box-sizing: border-box;

  li {
    list-style: none;
    text-transform: uppercase;

    .current_area, .current_link {
      text-decoration: underline;
      color: $text-color;
    }
  }
}

.open_menu {
  display: block;
}