// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------


/*
* Website logo / title
*/

header {
  padding: 4em 0 0;
  height: 16em;

  .logo {
    font-weight: bold;
    line-height: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

    div {
      flex-shrink: 0;
    }

    .logo__first_part {
      margin: -73px 0 0;
      animation-name: logo-first-part-show;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }

    .logo__second_part {
      font-size: 5em;
      opacity: 0;
      @include transform(scaleY(0));
      animation-name: logo-second-part-show;
      animation-delay: 1s;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes logo-first-part-show {
  from {
    @include transform(scaleX(0));
  }
  to {
    @include transform(scaleX(100%));
  }
}

@keyframes logo-second-part-show {
  to {
    opacity: 1;
    @include transform(scaleY(1));
  }
}
