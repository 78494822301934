/**
 * Basic typography style for copy text
 */

body {
  font: 1em $text-font-stack;
}

header h1 {
  font: 4em $text-font-stack;
}

h1 {
  font: 3em $app-title-bold-font-stack;
}