// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
  text-align: center;
  margin: 44px 0 0;
  padding: 0 0 20px;

  hr {
    opacity: 0;
    width: 0%;
    animation-name: footer-hr-show;
    animation-delay: 3s;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  p {
    opacity: 0;
    animation-name: footer-p-show;
    animation-delay: 3.5s;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
}

@keyframes footer-hr-show {
  to {
    opacity: 1;
    width: 90%;
  }
}

@keyframes footer-p-show {
  to {
    opacity: 1;
  }
}