.home {
  .applications {
    text-align: center;
  }

  .applications__application {
    display: inline-block;
    opacity: 0;
    animation-name: applications-show;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;

    img {
      transform: rotateX(6deg) rotateZ(-11deg);
      transform-style: preserve-3d;
      border-radius: 32px;
      box-shadow: 1px 1px 0 1px #E9C9B1, -1px 0 28px 0 rgba(34, 33, 81, 0.01), 28px 28px 28px 0 rgba(34, 33, 81, 0.25);
      width: 100%;
    }
  }
}

@keyframes applications-show {
  to {
    opacity: 1;
  }
}